import React, { useState } from 'react';
import '../../css/App.css';
import { Typography, Modal } from 'antd';
import image1 from '../../assets/photos/gallery/0BE9B534-B319-4D08-B4EF-9508207D1AA6.JPG';
import image2 from '../../assets/photos/gallery/4D4AEBFA-FEDD-4FE2-94AC-2CF71E687346.JPG';
import image3 from '../../assets/photos/gallery/44D6B5C0-2429-41D6-BD58-07D2F2B2A9DB.JPG';
import image4 from '../../assets/photos/gallery/272D7220-1540-44BB-B8A7-A5C94016A173.JPG';
import image5 from '../../assets/photos/gallery/731D199A-1A5E-4E1E-9B49-F6B353140FA0.JPG';
import image6 from '../../assets/photos/gallery/22494B3C-8333-47C3-A178-CBF3074154AC.JPG';
import image7 from '../../assets/photos/gallery/64552F5E-F875-415B-89A9-6D95C7BCF444.JPG';
import image8 from '../../assets/photos/gallery/85890F8C-8C29-4495-9110-8DEBDCB459F7.JPG';
import image9 from '../../assets/photos/gallery/A3F37730-AFB6-47D0-9778-E0C86FB1908B.JPG';
import image10 from '../../assets/photos/gallery/AEBDCD6E-B15F-4DCA-85E7-7DE3A0F9BD67.JPG';
import image11 from '../../assets/photos/gallery/B04CC098-F7E1-49A6-8267-05CFBF96605D.JPG';
import image12 from '../../assets/photos/gallery/B6E4D4E0-AAC1-40E0-98AE-C61FF9457732.JPG';
import image13 from '../../assets/photos/gallery/CD3AA464-E36C-4436-B930-649A4A2FE6F8.JPG';
import image14 from '../../assets/photos/gallery/EF2719A8-E82E-4DEE-A112-8B53055AE066.JPG';
import image15 from '../../assets/photos/gallery/FF4FD930-6E4E-4DB2-83B2-DAC2F2382BC1.JPG';

const { Title } = Typography;

function AboutUsContent() {
  const galleryImages = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (src) => {
    setSelectedImage(src);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setSelectedImage(null);
  };

  return (
    <div className="text-container">
      <Title style={{ color: '#d3d3d3' }}>About Us</Title>
      <Title level={5} style={{ color: '#d3d3d3', fontWeight: 'normal' }}>We met backcountry skiing course and have been adventuring together ever since. Here are some of our favorite photos from our travels over the years.</Title>
      <div className="gallery">
        {galleryImages.map((src, index) => (
          <div
            className="gallery-item"
            key={index}
            onClick={() => handleImageClick(src)}
          >
            <img src={src} alt={`Gallery ${index + 1}`} />
          </div>
        ))}
      </div>

      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleClose}
        centered
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        bodyStyle={{ padding: 0, background: 'transparent' }}
      >
        {selectedImage && (
          <img
            src={selectedImage}
            alt="Full view"
            style={{
              width: '100%',
              display: 'block',
              border: '5px solid grey',
              boxSizing: 'border-box',
            }}
          />
        )}
      </Modal>
    </div>
  );
}

export default AboutUsContent;
