import React from 'react';
import '../../css/App.css';
import { Typography } from 'antd';

const { Title } = Typography;

function StayContent() {
  return (
    <div className='text-container'>
      <header>
        <p>
          <Title style={{ color: '#d3d3d3', margin: '0.25rem 0' }} level={2}>Stay</Title>
        </p>

        <Title level={5} style={{ color: '#d3d3d3' }}>There are many good hotel options in Downtown Seattle, either a short Uber or reasonable walking distance from the venue!</Title>
        <Title level={5} style={{ color: '#d3d3d3' }}>
          If you'd like to stay near other guests travelling to Seattle, our recommendations are
          <a
            href="https://www.innatthemarket.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#0097FF', textDecoration: 'none' }}
          > Inn at the Market </a>
          and
          <a
            href="https://www.hyatt.com/thompson-hotels/seath-thompson-seattle"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#0097FF', textDecoration: 'none' }}
          > The Thompson.</a>
        </Title>
        <hr style={{ border: '1px solid #d3d3d3', margin: '0.25rem 0' }} />
        <p>
          <Title style={{ color: '#d3d3d3', margin: '0.25rem 0' }} level={2}>Explore</Title>
        </p>
        <p>
          <Title level={5} style={{ color: '#d3d3d3' }}>Seattle and the surrounding areas are home to some of the best coffee, food, hiking, and seemingly endless fun activities. We want to share our beautiful home with you!</Title>
          <Title level={5} style={{ color: '#d3d3d3' }}>If you're looking for fun ways to fill your time during your visit to the Seattle area, check out some of our recommendations below.
          </Title>
        </p>
        <hr style={{ border: '.5px solid #d3d3d3', margin: '0.25rem 0' }} />
        <Title level={3} style={{ color: '#d3d3d3', margin: '0.25rem 0' }}>Food</Title>
        <Title level={5} style={{ color: '#d3d3d3', margin: '0.25rem 0' }}>
          <a
            href="https://www.google.com/maps/@/data=!3m1!4b1!4m2!11m1!2sPb65dHRtTO2i6NHnISppGQ?g_ep=CAISEjI1LjA1LjMuNzIwMTgwNTk0MBgAILffASpjLDk0MjQyNDk5LDk0MjI0ODI1LDk0MjI3MjQ3LDk0MjI3MjQ4LDQ3MDcxNzA0LDQ3MDY5NTA4LDk0MjE4NjQxLDk0MjAzMDE5LDQ3MDg0MzA0LDk0MjA4NDU4LDk0MjA4NDQ3QgJVUw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#0097FF', textDecoration: 'none' }}
          >Coffee and Pastries</a>
        </Title>
        <Title level={5} style={{ color: '#d3d3d3', margin: '0.25rem 0' }}>
          <a
            href="https://www.google.com/maps/@/data=!3m1!4b1!4m2!11m1!2s45C1cRuiRFe5BBvHce6XXA?g_ep=CAISEjI1LjA1LjMuNzIwMTgwNTk0MBgAILffASpjLDk0MjQyNDk5LDk0MjI0ODI1LDk0MjI3MjQ3LDk0MjI3MjQ4LDQ3MDcxNzA0LDQ3MDY5NTA4LDk0MjE4NjQxLDk0MjAzMDE5LDQ3MDg0MzA0LDk0MjA4NDU4LDk0MjA4NDQ3QgJVUw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#0097FF', textDecoration: 'none' }}
          >The Complete List: Our favorite Seattle restaurants</a>
        </Title>
        <hr style={{ border: '.5px solid #d3d3d3', margin: '0.25rem 0' }} />
        <Title level={3} style={{ color: '#d3d3d3', margin: '0.25rem 0' }}>Seattle Activities</Title>
        <Title level={5} style={{ color: '#d3d3d3', margin: '0.25rem 0' }}>
          <a
            href="https://www.google.com/maps/@/data=!3m1!4b1!4m2!11m1!2sURfvn6CtSE6ZNs47AwzStg?g_ep=CAISEjI1LjA1LjMuNzIwMTgwNTk0MBgAILffASpjLDk0MjQyNDk5LDk0MjI0ODI1LDk0MjI3MjQ3LDk0MjI3MjQ4LDQ3MDcxNzA0LDQ3MDY5NTA4LDk0MjE4NjQxLDk0MjAzMDE5LDQ3MDg0MzA0LDk0MjA4NDU4LDk0MjA4NDQ3QgJVUw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#0097FF', textDecoration: 'none' }}
          >Fun Seattle Sights and Activities</a>
        </Title>
        <hr style={{ border: '.5px solid #d3d3d3', margin: '0.25rem 0' }} />
        <Title level={3} style={{ color: '#d3d3d3', margin: '0.25rem 0' }}>Greater Seattle Area</Title>
        <Title level={5} style={{ color: '#d3d3d3', margin: '0.25rem 0' }}>
          <a
            href="https://www.google.com/maps/@/data=!3m1!4b1!4m2!11m1!2s3QJMG3NJRJeZ7P6Pbl0CCQ?g_ep=CAISEjI1LjA1LjMuNzIwMTgwNTk0MBgAILffASpjLDk0MjQyNDk5LDk0MjI0ODI1LDk0MjI3MjQ3LDk0MjI3MjQ4LDQ3MDcxNzA0LDQ3MDY5NTA4LDk0MjE4NjQxLDk0MjAzMDE5LDQ3MDg0MzA0LDk0MjA4NDU4LDk0MjA4NDQ3QgJVUw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#0097FF', textDecoration: 'none' }}
          >Explore Washington and the PNW</a>
        </Title>
        <hr style={{ border: '.5px solid #d3d3d3', margin: '0.25rem 0' }} />
      </header>
    </div>
  );
}

export default StayContent;