
import React from 'react';
import '../../css/App.css';
import { Typography } from 'antd';

const { Title } = Typography;

function RegistryContent() {
  return (
    <div className='text-container'>
      <header>
        <p>
        <Title style={{ color: '#d3d3d3' }} level={3}>Wedding Registry</Title>
        <Title style={{ color: '#d3d3d3' }} level={5}>The greatest gift we could ever receive is your love, support and presence in our lives.</Title>
          <Title style={{ color: '#d3d3d3' }} level={5}>No gifts are expected, but if you'd like to celebrate with a gift, you can find our registry here →            <a
              href="https://www.zola.com/registry/harryandmonika"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#0096FF', textDecoration: 'none' }}
            >
              Click Me
            </a></Title>
        </p>
      </header>
    </div>
  );
}

export default RegistryContent;