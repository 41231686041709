import React from 'react';
import '../../css/App.css';
import { Typography } from 'antd';
import ReactGA from "react-ga4";

ReactGA.initialize("G-Q3EM97MDYG");

ReactGA.send({ hitType: "pageview", page: "/", title: "Custom Title" });

const { Title } = Typography;

function HomeContent() {
  return (


    <div className='text-container'>
      <Title style={{ color: '#d3d3d3' }}>Harry and Monika</Title>
      <Title style={{ color: '#d3d3d3' }} level={3}>September 27, 2025</Title>
      <Title style={{ color: '#d3d3d3' }} level={4}>Seattle WA</Title>
    </div>
  );
}

export default HomeContent;