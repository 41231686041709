// MainLayout.js
import React from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import Navbar from '../components/Nav'; // Adjust the path according to your folder structure
import HomeContent from "../components/HomeContent";
import ScheduleContent from '../components/ScheduleContent';
import StayContent from '../components/StayContent';
import RSVPContent from '../components/RSVP';
import RegistryContent from '../components/Registry';
import "../css/App.css";
import AboutUsContent from '../components/AboutUs';


const MainLayout = () => {


  const location = useLocation();

  const getSelectedKey = () => {
    switch (location.pathname) {
      case '/':
        return ['1'];
      case '/schedule':
        return ['2'];
      case '/stay':
        return ['3'];
      case '/rsvp':
        return ['4'];
      case '/registry':
        return ['5'];
      case '/aboutus':
        return ['6'];
      default:
        return ['1'];
    }
  };

  const getSelectedContent = () => {
    switch (location.pathname) {
      case '/':
        return <HomeContent />;
      case '/schedule':
        return <ScheduleContent />;
      case '/stay':
        return <StayContent />;
      case '/rsvp':
        return <RSVPContent />;
      case '/registry':
        return <RegistryContent />;
      case '/aboutus':
        return <AboutUsContent />;
      default:
        return <HomeContent />;
    }
  };

  const getBackgroundClass = () => {
    switch (location.pathname) {
      case '/':
        return 'home-bg'; // Class for Home background
      case '/schedule':
        return 'schedule-bg'; // Class for Schedule background
      case '/stay':
        return 'stay-bg'; // Class for Stay background
      case '/rsvp':
        return 'rsvp-bg'; // Class for RSVP background
      case '/registry':
        return 'registry-bg';
      case '/aboutus':
        return 'aboutus-bg';
      default:
        return 'default-bg'; // Default class
    }

  };

  return (
    <div className={`Home ${getBackgroundClass()}`}>
      <Navbar selectedKey={getSelectedKey()}>{getSelectedContent()}</Navbar> {/* Pass the selected key and children */}
      <Routes>
        <Route path="/" />
        <Route path="/schedule" />
        <Route path="/stay" />
        <Route path="/rsvp" />
        <Route path="/registry" />
        <Route path="/aboutus" />
      </Routes>
    </div>
  );


};

export default MainLayout;
