import React from 'react';
import '../../css/App.css';
import { Typography } from 'antd';

const { Title } = Typography;

function ScheduleContent() {
  return (
    <div className='text-container'>
      {/* Main centered title */}
      <header style={{ textAlign: 'center', marginBottom: '2rem' }}>
        <Title style={{ color: '#d3d3d3' }} level={2}>
          Weekend Schedule
        </Title>
      </header>

      {/* Friday Section */}
      <section className='day-section' style={{ textAlign: 'left', marginBottom: '1.5rem' }}>
        <Title style={{ color: '#d3d3d3' }} level={3}>
          Friday
        </Title>
        {/* Event: Welcome Dinner with left/right alignment */}
        <div
          style={{
            display: 'flex',
            width: '100%',
            paddingLeft: '1rem',
            paddingRight: '1rem'
          }}
        >
          <span style={{ color: '#d3d3d3', flex: 1, textAlign: 'left' }}>
            Welcome Dinner
          </span>
          <span style={{ color: '#d3d3d3', flex: 1, textAlign: 'right' }}>
            TBD
          </span>
        </div>
      </section>

      {/* Horizontal Line between Friday and Saturday */}
      <hr style={{ border: '1px solid #d3d3d3', margin: '2rem 0' }} />

      {/* Saturday Section */}
      <section className='day-section' style={{ textAlign: 'left', marginBottom: '1.5rem' }}>
        <Title style={{ color: '#d3d3d3' }} level={3}>
          Saturday
        </Title>
        {/* Event: Ceremony with time/location */}
        <div
          style={{
            display: 'flex',
            width: '100%',
            paddingLeft: '1rem',
            paddingRight: '1rem'
          }}
        >
          <span style={{ color: '#d3d3d3', flex: 1, textAlign: 'left' }}>
            Ceremony
          </span>
          <span style={{ color: '#d3d3d3', flex: 1, textAlign: 'right' }}>
            6PM{' '}
            <a
              href="https://maps.app.goo.gl/FpxACeozES1yzauc6"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#d3d3d3', textDecoration: 'none' }}
            >
              Olympic Sculpture Park
            </a>
          </span>
        </div>
        {/* Reception note in italics */}
        <div style={{ paddingLeft: '1rem', marginTop: '0.5rem' }}>
          <em style={{ color: '#d3d3d3' }}>Reception to Follow</em>
        </div>
      </section>

      {/*
      Uncomment and add Sunday content if needed:
      <section className='day-section' style={{ textAlign: 'left' }}>
        <Title style={{ color: '#d3d3d3' }} level={3}>
          Sunday
        </Title>
        // Sunday content goes here
      </section>
      */}
    </div>
  );
}

export default ScheduleContent;
