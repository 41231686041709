
import React from 'react';
import '../../css/App.css';
import { Typography } from 'antd';

const { Title } = Typography;

function RSVPContent() {
  return (
    <div className="text-container">
      <header>
        <p>
          <Title style={{ color: '#d3d3d3' }} level={3}>
            Please RSVP below
          </Title>
        </p>
      </header>
      <iframe
      title="RSVP Form"
        className="airtable-embed"
        src="https://airtable.com/embed/appQcSxjjgTHyKDH4/pagPeEN0GYo5kAg12/form"
        frameBorder="0"
        onWheel={() => {}}
        width="100%"
        height="533"
        style={{ background: 'transparent', border: '1px solid #ccc' }}
      ></iframe>
    </div>
  );
}

export default RSVPContent;